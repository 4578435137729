export const environment = {
  production: true,
  url: 'https://api-v2.smcdao.com',
  // auth_url: 'https://staging-auth.smcdao.com',
  auth_url: 'https://auth.smcdao.com',
  socket_url: 'https://socket.smcdao.com',
  trackingCode: 'G-HVLMT4ZJ8Z',
  exchangeApiUrl: 'https://exchange.api.smcdao.com',
  smcEscrowAddress: '0x55c33A666796134A0F4916aeA38b56ccc76B0E5F',
  smcWallet: '0xe9D28865261636aBD69D33F434EEDe21064b0a29',
  cloudinary: {
    cloud_name: 'dk22xa9qh',
  },
  chain: {
    id: 56,
    name: 'BNB Smart Chain',
    network: 'bsc',
  },
};
