import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AdminService } from './services/admin/admin.service';
import { StoreService } from './services/store/store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'smcdao-dapp';
  userId: string | null = null;
  user: any;
  event$: any;

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    /*const routes = this.router.url.split("/");
    this.userId = routes[2];
    if (this.userId.match(/^[0-9a-fA-F]{24}$/)) this.getUsersDetails();*/
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminService,
    private storeService: StoreService
  ) {
    this.event$ = this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationEnd) {
      }
    });
  }

  async getUsersDetails() {
    this.adminService.getUsersDetails(this.userId).subscribe({
      next: (res: any) => {
        this.user = res.data;
      },
    });
  }
}
