import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Observable, of, pipe, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { StoreService } from '../store/store.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { WsService } from '../ws/ws.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private url = environment.url + '/admin/users';
  private adminUrl = environment.url + '/admin';
  allPages = ['/farms', '/users', '/staff', '/reports'];

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
    private wsService: WsService
  ) {}

  getAllUsers(
    data:
      | {
          resultType: string;
          status:
            | 'pending'
            | 'approved'
            | 'rejected'
            | 'suspended'
            | 'incomplete';
          page: any;
          limit: any;
        }
      | {
          resultType: 'all';
          status:
            | 'pending'
            | 'approved'
            | 'rejected'
            | 'suspended'
            | 'incomplete';
          page: 1;
          limit: 10;
        },
    filter = ''
  ) {
    const { resultType } = data;
    const query =
      (resultType === 'all'
        ? 'isDeleted=false'
        : resultType === 'archived'
        ? 'isArchived=true'
        : resultType === 'deleted'
        ? 'isDeleted=true'
        : 'isArchived=false') + (filter ? filter : '');
    return this.http.get(
      `${this.url}?page=${data.page}&status=${
        String(data.status) != 'undefined' ? data.status : ''
      }&limit=${data.limit}&${query}`
    );
  }

  getAllAdmins(
    data: { page: any; limit: any } | { page: 1; limit: 10 },
    filter = ''
  ) {
    return this.http.get(
      `${this.adminUrl}?page=${data.page}&limit=${data.limit}${filter}`
    );
  }

  getUsersCount(filter = '') {
    return this.http.get(`${this.url}/count?filter=true${filter || ''}`);
  }

  getUsersDetails(id: string | null) {
    return this.http.get(`${this.url}/details/${id}`);
  }

  getUsersActivities(id: string | null, page = 1, limit = 10) {
    return this.http.get(
      `${this.url}/${id}/activities?page=${page}&limit=${limit}`
    );
  }

  getUsersReferrals(
    data:
      | { page: any; limit: any; telegramId: string }
      | { page: 1; limit: 10; telegramId: null }
  ) {
    return this.http.get(
      `${this.url}/${data.telegramId}/referrals?page=${data.page}&limit=${data.limit}`
    );
  }

  getUsersTransactions(
    data:
      | { page: any; limit: any; userId: string }
      | { page: 1; limit: 10; userId: null }
  ) {
    return this.http.get(
      `${this.url}/${data.userId}/transactions?page=${data.page}&limit=${data.limit}`
    );
  }

  getUsersTransactionTotals(userId: string) {
    return this.http.get(`${this.url}/${userId}/transactionTotal`);
  }

  removeStatus(user: any) {
    for (const key of Object.keys(user)) {
      if (key.indexOf('status') > -1) {
        delete user[key];
      }
    }

    return user;
  }

  updateProfile(data: { _id: any }) {
    data = this.removeStatus(data);

    return this.http.patch(`${environment.url}/users/${data._id}`, data).pipe(
      tap(() => {
        this.wsService.emit('updateMembers', { userId: data._id });
      })
    );
  }

  adminUpdateProfile(data: any) {
    data = this.removeStatus(data);
    return this.http.patch(`${this.url}/${data._id}`, data).pipe(
      tap(() => {
        this.wsService.emit('updateMembers', { userId: data._id });
      })
    );
  }

  adminUpdateProfilePicture(userId: string, data: any) {
    return this.http
      .patch(`${environment.url + '/users'}/${userId}/profile-picture`, data)
      .pipe(
        tap(() => {
          this.wsService.emit('updateMembers', { userId });
        })
      );
  }

  getActivities(dateFilter: string) {
    return this.http.get(
      `${this.adminUrl}/activities${
        dateFilter && dateFilter != 'undefined' ? '?' + dateFilter : ''
      }`
    );
  }

  getCountries() {
    return this.http.get(`${environment.url}/users/countries`);
  }

  getExchanges() {
    return this.http.get(`${environment.url}/users/supportedExchanges`);
  }

  sendPoints(data: any) {
    return this.http.post(`${environment.url}/users/send`, data).pipe(
      tap(() => {
        this.wsService.emit('updateMembers', { userId: data._id });
        this.wsService.emit('updateActivities', { userId: data._id });
        this.wsService.emit('updateUserTransactions', {});
        this.wsService.emit('updatePointsRanking', {});
        this.wsService.emit('updateDashboardStat', {});
      })
    );
  }

  sendEmailBlast(data: { subject: string; html: string }) {
    return this.http.post(`${this.adminUrl}/email-blast`, data).pipe(
      tap(() => {
        this.wsService.emit('updateEmailBroadcast', {});
      })
    );
  }

  getEmailBlasts() {
    return this.http.get(`${this.adminUrl}/email-blast`);
  }

  closeModal() {
    let el: any = document.getElementsByClassName('btn-close');
    for (let i = 0; i < el.length; i++) {
      el[i].click();
    }
  }

  openModal(buttonClassName) {
    let el: any = document.getElementsByClassName(buttonClassName);
    for (let i = 0; i < el.length; i++) {
      el[i].click();
    }
  }

  openModalById(id: string) {
    ($(`#${id}`) as any).modal('show');
  }

  closeModalById(id: string) {
    ($(`#${id}`) as any).modal('hide');
  }

  takeAction(data: { id: any }) {
    return this.http.put(`${this.url}/${data.id}`, data);
  }

  updateUserPoint(userId: string, data: any) {
    return this.http.put(`${this.url}/${userId}/points`, data).pipe(
      tap(() => {
        this.wsService.emit('updateMembers', { userId });
        this.wsService.emit('updateActivities', { userId });
        this.wsService.emit('updatePointsRanking', {});
        this.wsService.emit('updateDashboardStat', {});
      })
    );
  }

  updateBulkPoints(userIds: string[], data: any, type = 'all') {
    return this.http
      .put(`${this.url}/bulk-points`, { userIds, ...data, type })
      .pipe(
        tap(() => {
          this.wsService.emit('updateMembers', {});
          this.wsService.emit('updateActivities', {});
          this.wsService.emit('updatePointsRanking', {});
          this.wsService.emit('updateDashboardStat', {});
        })
      );
  }

  updateUser(data: any, id: any) {
    return this.http.patch(`${this.url}/${id}`, data).pipe(
      tap(() => {
        this.wsService.emit('updateMembers', { userId: id });
      })
    );
  }

  alterUserRole(id: string, role: string) {
    return this.http.patch(`${this.url}/${id}/update-role`, { role }).pipe(
      tap(() => {
        this.wsService.emit('updateMembers', { userId: id });
        this.wsService.emit('updateActivities', {});
      })
    );
  }

  alterUserStatus(
    id: string,
    status: 'rejected' | 'approved' | 'pending',
    reason: string
  ) {
    return this.http
      .patch(`${this.url}/${id}/update-status`, { status, reason })
      .pipe(
        tap(() => {
          this.wsService.emit('updateMembers', { userId: id });
          this.wsService.emit('updateActivities', {});
        })
      );
  }

  suspendUser(id: string, data: Record<string, any>) {
    return this.http.patch(`${this.url}/${id}/suspend`, data).pipe(
      tap(() => {
        this.wsService.emit('updateMembers', { userId: id });
        this.wsService.emit('updateActivities', {});
      })
    );
  }

  save(user: any) {
    localStorage['currentUser'] = JSON.stringify(user);
  }

  isAdmin() {
    const user = this.auth.loggedUser().userDetails;
    return user.role?.indexOf('admin') > -1;
  }

  role() {
    return this.auth.loggedUser().role;
  }

  // hasPermission(permission: string) {
  //   if (!this.auth.loggedUser().permissions) {
  //     localStorage.clear();
  //     this.router.navigate(['/auth']);
  //   }
  //   const permissions = this.auth.loggedUser().role;
  //   permission = '/' + permission;

  //   // check if user does not have permission
  //   if (
  //     permissions.findIndex(
  //       (x: { routes: string }) => x.routes === permission
  //     ) === -1 &&
  //     !this.isAdmin()
  //   ) {
  //     // find a default page for the user
  //     const defaultPage = permissions.find((x: { routes: string }) =>
  //       this.allPages.includes(x.routes)
  //     );

  //     return { allow: false, route: defaultPage.routes };
  //   }

  //   return { allow: true };
  // }

  remove(id: any) {
    return this.http.delete(`${this.url}/${id}`).pipe(
      pipe(
        tap(() => {
          this.wsService.emit('updateMembers', {});
          this.wsService.emit('updateDeletedMembers', {});
          this.wsService.emit('updateActivities', { userId: id });
          this.wsService.emit('updatePointsRanking', {});
          this.wsService.emit('updateDashboardStat', {});
        })
      )
    );
  }

  generateWalletAddress(type = 'usdt') {
    return this.http
      .post(`${environment.url}/users/generate_adddress`, {})
      .pipe(
        pipe(
          tap(() => {
            this.wsService.emit('updateMembers', {});
          })
        )
      );
  }

  getPointTxHistory(
    data:
      | {
          page: any;
          limit: any;
        }
      | {
          page: 1;
          limit: 10;
        },
    filter = ''
  ) {
    return this.http.get(
      `${environment.url}/admin/points/history?page=${data.page}\&limit=${data.limit}`
    );
  }
  getWikiCatPrice(currency = 'usd') {
    return this.http.get(`${environment.url}/wikicat-price/${currency}`, {});
  }

  retrieve() {
    return JSON.parse(localStorage['currentUser'] || 'null');
  }

  getUserProfile(id: any) {
    return this.http.get(`${environment.url}/admin/users/details/${id}`, {});
  }
}
