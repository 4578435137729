import { Injectable } from '@angular/core';
import io from 'socket.io-client';
import { Observable, Observer, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StoreService } from 'src/app/services/store/store.service';

@Injectable({
  providedIn: 'root',
})
export class WsService {
  socket: SocketIOClient.Socket;
  socket_url = environment.socket_url;
  currentUser: any;

  constructor(private storeService: StoreService) {
    const token = this.storeService.getAccessToken();

    this.socket = io(this.socket_url, {
      query: {
        token: token,
      },
    });

    this.socket.on('connect', () => {
      console.log('WebSocket connected');
    });
  }

  listen(eventname: string): Observable<any> {
    return new Observable((observer) => {
      this.socket?.on(eventname, (data) => {
        observer.next(data);
      });
    });
  }

  emit(eventname: string, data: any): void {
    this.socket.emit(eventname, data);
  }
}
