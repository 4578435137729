import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  constructor() {}

  store(data: any) {
    localStorage.setItem('user', JSON.stringify(data));
  }

  storeCurrentUser(data: any) {
    localStorage.setItem('currentUser', JSON.stringify(data));
  }

  storeCurrentMember(data: any) {
    localStorage.setItem('member', JSON.stringify(data));
  }

  get() {
    const user: any = localStorage.getItem('user');
    return JSON.parse(user);
  }

  getCurrentUser() {
    const username: any = localStorage.getItem('currentUser');
    return JSON.parse(username);
  }

  getCurrentMember() {
    const member: any = localStorage.getItem('member');
    return JSON.parse(member);
  }

  getAccessToken() {
    const user = this.get();
    if (user !== null) return user.jwt;
  }

  getRefreshToken() {
    const user = this.get();
    if (user !== null) return user.refreshToken;
  }

  logout() {
    localStorage.clear();
  }
}
