import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../services/auth/auth.service';
import { ElMessageService, ElNotificationService } from 'element-angular';
import { Router } from '@angular/router';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  loading = false;
  constructor(
    private spinner: NgxSpinnerService,
    public auth: AuthService,
    private alert: ElMessageService,
    private router: Router,
    private notify: ElNotificationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.method === 'POST' ||
      req.method === 'PUT' ||
      req.method === 'PATCH' ||
      req.method === 'DELETE'
    ) {
      const silent = req.url.indexOf('silent=true') > -1;

      if (!silent) this.spinner.show();

      this.loading = true;
    }

    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`,
      },
    });

    // @ts-ignore
    return next.handle(req).pipe(
      finalize(() => {
        if (this.loading) {
          this.loading = false;
          this.spinner.hide();
        }
      }),
      tap(
        () => {},
        (error) => {
          const { status } = error;

          if (status === 401) this.auth.logout();

          const message = error.error.message;
          this.notify.setOptions({
            zIndex: 5000,
          });

          if (message === 'User not found' || message === 'Invalid user ID') {
            this.router.navigate(['/members']);
          }

          if (req.method !== 'GET')
            this.notify['error'](message, error.statusText);

          return error;
        }
      )
    );
  }
}
