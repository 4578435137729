import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { Observable, of, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
// import { environment } from 'src/environments/environment.staging';
import { StoreService } from '../store/store.service';
import { Router } from '@angular/router';
import { WsService } from '../ws/ws.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private url = environment.auth_url + '/auth';

  constructor(
    private http: HttpClient,
    private store: StoreService,
    private router: Router,
    private helper: JwtHelperService,
    private wsService: WsService
  ) {}

  isAuthenticated() {
    const token = this.getToken();

    if (token == null) return false;

    if (token.split('.').length !== 3) return false;

    return !this.helper.isTokenExpired(token);
  }

  generateOtp(data: any) {
    return this.http.post(`${this.url}/login/token`, data);
  }

  login(data: any) {
    return this.http.post(`${this.url}/login`, data);
  }

  register(data: any) {
    return this.http.post(`${this.url}/register`, data);
  }

  verify(data: any) {
    return this.http.post(`${this.url}/verify-account`, data).pipe(
      tap(() => {
        this.wsService.emit('updateMembers', {});
        this.wsService.emit('updateDashboardStat', {});
        this.wsService.emit('updateActivities', {});
        this.wsService.emit('updateReferrals', {});
      })
    );
  }

  fetchPlaces() {
    return this.http.get(`${this.url}/places`, {});
  }

  searchPlaces({
    search,
    latitude = 6.5480282,
    longitude = 3.1191493,
  }: {
    search: string;
    latitude?: number;
    longitude?: number;
  }) {
    return this.http.get(
      `${this.url}/places/search?search=${search}&latitude=${latitude}&longitude=${longitude}`
    );
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/auth']);
  }

  getToken() {
    return this.store.getAccessToken();
  }

  loggedUser() {
    return JSON.parse(localStorage['user'] || 'null');
  }

  isApproved() {
    const user = this.loggedUser();
    return user.status == 'approved';
  }

  isAdmin() {
    const role = (this.loggedUser().userDetails?.role || 'user') as string;

    return (
      role.indexOf('admin') > -1 ||
      role.indexOf('moderator') > -1 ||
      role.indexOf('finance') > -1
    );
  }

  forgotPassword(data: any) {
    return this.http.post(`${this.url}/staff-forgot-password`, data);
  }

  resetPassword(data: any) {
    return this.http.post(`${this.url}/staff-reset-password`, data);
  }
}
